<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :backSlash="backSlash" :backSlashParent="backSlashParent" :subPage="subPage"
            :subPageUrl="subPageUrl" :pageUrl="pageUrl" :appPageName="appPageName" :pageName="pageName" back="true" />
          <breadcrumbComp class="hidden-md-and-up" :backSlashParent="backSlashParent" :subPageUrl="subPageUrl" :pageUrl="pageUrl"
            :appPageName="appPageName" :pageName="pageName" back="true" />
          <!-- E:Breadcrumbs -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-5">
              <v-card outlined color="transparent" class="pa-0 ma-0 containerbox">
                <v-card-text class="pb-0 pt-0">
                  <!-- To send the Page name to child component -->
                  <userForm :pageName="pageName" :edit="edit" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import userForm from './app-user-form-comp.vue';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import AddFavourite from "../utility/js/addFav"
export default {
  data: () => ({
    edit: true,
    pageName: 'Add User',
    backSlash: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    subPage: "",
    subPageUrl: "",
    pageUrl: "",
    appPageName: "",
    backSlashParent: true
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getParentPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
    this.subPageUrl = data.subPageUrl
    this.appPageName = data.appPageName
  },
  methods: {},
  components: {
    userForm,
    breadcrumbComp,
  },
};
</script>
<style scoped></style>
